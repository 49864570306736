const ValidationConstants={
email:"Name should be 30 characters long.",
valEmail:"Please enter valid email address.",
reqEmail:"Please enter email address.",
password:" Password should be at least 6 characters long.",
fname:"First name should be at least 2 characters long.",
lname:"Last name should be at least 2 characters long.",
reqPassword:"Please enter password.",
fName:"Please enter first name.",
lName:"Please enter last name.",
confirmPassword:"Please enter confirm password.",
name:"Please enter name.",
description:"Description should be 30 characters long.",
valDescription:'Please enter description.',
space:"Space is not allowed in password.",
confirmSpace:"Space is not allowed in confirm password.",
match:"Password and confirm password must be same.",
title:"Must be 30 characters.",
reqTitle:"Please enter title.",
reqBudget:"Please enter budget.",
reqAddress:"Please enter address.",
newSpace:"Space is not allowed.",
terms:'Please accept terms and conditions.',
supportedImages:"Only jpg, jpeg, png and svg formats are supported.",

}

export default ValidationConstants;