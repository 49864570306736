
const AppConstants={
    SignUp:"Sign Up",
    login:"Login",
    LogIn:"Log In",
    Send:"Send",
    placeholder1:"Enter Email Address",
    placeholder2:"Enter Password",
    ForgetPass:"Forgot Password?",
    Keep:"Keep me Sign In",
    NoAccount:"Don't have an Monoplug account? Sign Up",
    Terms:"Accept Terms and Conditions",
    VerficationLink:"A verification link will be sent to the email address.",
    Account:"Already have an account? Login",
    LoremIpsum:"What is Lorem Ipsum?",
    Definition:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.",
    TotalGigs:"Total Number of Gigs",
    ActiveJobs:"Total Number of Active Jobs",
    ForgotPass:"Forgot Password",
    linkVerification:"Password reset link will be sent to the registered email address.",
    changePass:"Change password",
    Update:"Update",
    LoremIpsum1:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy textever since the 1500s,",
    News:"News",
    SeeAll:"See All",
    Stories:"Stories",
    LoremIpsumModal:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    dateTime:"05 May 2021 10:23 AM",
    LoremIpsum2:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy textever since the 1500s, when an unknown printer took a gallery oftype and scrambled it to make a type specimen book. It has survived not only five centuries.",
//    Create Job
    createJob:"Create Job",
    Artist:"Artist",
    Band:"Band",
    Category1:"Select Category 1",
    Category2:"Select Category 2",
    Category3:"Select Category 3",
    subCategory1:"Select Sub Category 1",
    subCategory2:"Select Sub Category 2",
    subCategory3:"Select Sub Category 3",
    Cancel:"Cancel",
    postJob:"Post Job",
    allArtists:"All Artists",
    JobTitle:"Job Title",
    rockstar:"Rockstars",
    Lorem:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.  Lorem Ipsum has been the...",
    Invite:"Invite",

    // ping
    createGig:"Create Gig",
    Send:"Send",

    // notification
    Notification:"Notifications",
    proposal:"Proposal",
    time:"Today 01:20PM",
    date:"05 May 2021",
    notificationDetails:"Notification Details",
    type:"Type:",
    description:"Description:",
    Lorem1:"Lorem Ipsum is simply dummy text of the printingand typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
     
    // message
    recentEntertainer:"Recent Entertainers",
    Deniel:"Deniel",
    info:"Lorem Ipsum is simply dummy dfd...",
    noConvo:"No Conversation Yet",
    chat:"Lorem Ipsum is simply dummy text of the printing",

    // Jobs
    money:"$300",
    looking:"Looking For",
    jobDetails:"Job Details",

    // Footer
      address1:"107, Sushil tower, ",
      address2:"mahamana nagar colony sunderpur Varanasi",
      address3:"",
      privacy:" Privacy Policy",
      tc:" Terms & Conditions",
      contact:"Contact Us",
      download:"Download The App",
      appStore:" Download the app from Apple Store or windows store",
      follow:"Follow Us:",

    //   How it works
    work:"How It Works",
    para1:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    para2:" It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
    para3:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content",

    // contact message
    thankyou:"Thank you for enquiry!",
    feedback:"A member of our support team will respond as soon as possible.",
    home:"Home",
    disputeMessage:"Thank you for raising the dispute!",

    // Resetpassword
    resetPassword:"Reset Password",

    // contact us
    contact:"Contact Us",
    submit:"Submit",

    passwordChange:"Your password has been reset successfully",
    expired:"Your session has been expired.",
    uploadImage:"Click to upload image",
    changeImage:"Click to change image",
    upload:"Please upload image."
}

export default AppConstants;