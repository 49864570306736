const ApiConstants={
login:"LOGIN",
getPost:'GETPOST',
getPostSuccess:'GETPOSTSUCCESS',
getPostFail:'GETPOSTFAIL',
signup:"SIGNUP",
dashboard:"DASHBOARD",
loginSuccess:"Login_success",
loginFail:"Login_fail",
loginError:"Login_error",
signupSuccess:"Signup_success",
signupFail:"Signup_fail",
signupError:"Signup_error",
forgotPass:"FORGOT_PASSWORD",
forgotSuccess:"ForgotPass_success",
forgotFail:"ForgotPass_fail",
forgotError:"ForgotPass_error",
dashboardSuccess:"Dashboard_success",
dashboardFail:"Dashboard_fail",
dashboardError:"Dashboard_error",
contact:"CONTACT",
contactSuccess:"Contact_success",
contactFail:"Contact_fail",
contactError:"Contact_error",
setPass:"SET_PASSWORD",
setPassSucess:"SetPass_Success",
setPassFail:"SetPass_Fail",
setPassError:"SetPass_Error",
ResetSuccess:"ResetSuccess",
category:"CATEGORY",
categorySuccess:"Category_success",
categoryFail:"Category_fail",
categoryError:"Category_error",
resetPassword:"RESET_PASSWORD1",
resetPassSuccess:"Reset_Success",
resetPassFail:"Reset_Fail",
resetPassError:"Reset_Error",
movieList:"MOVIE_LIST",
movieListFail:"MOVIE_LIST_FAIL",
movieListSuccess:"MOVIE_LIST_SUCCESS",
createPayment:"CREATE_PAYMENT",
createPaymentFail:"CREATE_PAYMENT_FAIL",
createPaymentSuccess:"CREATE_PAYMENT_SUCCESS",


createMovie:"CREATE_MOVIE",
createMovieFail:"CREATE_MOVIE_FAIL",
createMovieSuccess:"CREATE_MOVIE_SUCCESS",
}

export default ApiConstants;