import React, { useState } from "react";
import { useEffect } from "react";
import HomeHeader from "../../CustomComponents/HomeHeader";
import wp1867328 from "../../Assets/Images/wp1867328.png";
import AddNewPaymentMethod from "./InvestPopup";

function Descriptions(props) {
  const [tab,setTab]=useState(1)
  const [paymentPopup, setPaymentPopup] = useState(false);

  useEffect(() => {
    console.log('dzkdkjnsjkdhds',props);
  }, []);
  let item=props?.location?.state?.item
  return (
    <div>
      {/* <HomeHeader></HomeHeader> */}
      <div className="inversContainer">
      <div style={{ position: "absolute", top: 0, width: "100%",backgroundColor:'rgb(6,8,31)' }}>
            <HomeHeader></HomeHeader>
          </div>
          <div style={{height: "100vh",}}>

        <img style={{ width: "100vw", height: "100%" }} src={props?.location?.state?.item?.backdrop_path?props?.location?.state?.item?.backdrop_path:props?.location?.state?.item?.imgUrl} />
         <div style={{ position: "absolute", top: "100%", width: "100%" }}>
            <button
              onClick={()=>{
                setPaymentPopup(true)
              }}
              type="submit"
              class="button-contact common_btn btnabs"
            >
              {"Invest"}
            </button>
          </div>
          </div>

        {/* <button type="submit" class="button-contact common_btn btnabs">
          {"Invest"}
        </button> */}
        
         
      </div>
      <section class="pt-3 welcome_bg sliders">
        <div class="container">
          <div class="row">
            <div class="col-md-1"></div>
            <div onClick={()=>{setTab(1)}} style={{width:'15%'}} class="col-md-2 tab-bg tab-data text-center text-white ">
              {/* <a class="text-white" href=""> */}
                Info
              {/* </a> */}
            </div>
            <div onClick={()=>{setTab(2)}} style={{width:'25%'}} class="col-md-2 tab-data text text-center text-white">
              {/* <a class="text-white" href=""> */}
              Financials
              {/* </a> */}
            </div>
            <div onClick={()=>{setTab(3)}} style={{width:'24%'}} class="col-md-2 tab-data text-center text-white">
              {/* <a class="text-white" href=""> */}
                Graphics
              {/* </a> */}
            </div>
            <div onClick={()=>{setTab(4)}} style={{width:'16%'}} class="col-md-2 tab-data text-center text-white">
              {/* <a class="text-white" href=""> */}
                Perks
              {/* </a> */}
            </div>
            <div onClick={()=>{setTab(5)}} style={{width:'20%'}} class="col-md-2 tab-bg-1 tab-data text-center text-white">
              {/* <a class="text-white" href=""> */}
                Extras
              {/* </a> */}
            </div>
            <div class="col-md-1"></div>
          </div>

          <div class="row">
            <div class="col-md-12"></div>
          </div>
        </div>
        <div class="container mt-5">
          <div class="bg-wrapper">
            <div class="row">
              <div class="col-12">
                <h3 class="title-text">Description</h3>
              </div>
              <div class="col-12">
                <p class="subtitle-text">
                  {item?.description}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <div class="container mt-5">
          <div class="bg-wrapper">
            <div class="row">
              <div class="col-md-2">
                <figure class="figure">
                  <img
                    src={require("./Mask Group 12.png").default}
                    class="figure-img img-fluid shape-round"
                    alt="..."
                  />
                 
                </figure>
              </div>
              <div class="col-md-2">
                <figure class="figure">
                  <img
                    src={require("./Mask Group 13.png").default}
                    class="figure-img img-fluid shape-round"
                    alt="..."
                  />
                
                </figure>
              </div>
              <div class="col-md-2">
                <figure class="figure">
                  <img
                    src={require("./Mask Group 13.png").default}
                    class="figure-img img-fluid shape-round"
                    alt="..."
                  />
                 
                </figure>
              </div>
              <div class="col-md-2">
                <figure class="figure">
                  <img
                    src={require("./Mask Group 13.png").default}
                    class="figure-img img-fluid shape-round"
                    alt="..."
                  />
                
                </figure>
              </div>
              <div class="col-md-2">
                <figure class="figure">
                  <img
                    src={require("./Mask Group 13.png").default}
                    class="figure-img img-fluid shape-round"
                    alt="..."
                  />
                  <figcaption class="figure-caption text-center">
                    Chad Stahels <br />
                    <span class="desigination">(Director)</span>
                  </figcaption>
                </figure>
              </div>
              <div class="col-md-2">
                <figure class="figure">
                  <img
                    src={require("./Mask Group 13.png").default}
                    class="figure-img img-fluid shape-round"
                    alt="..."
                  />
                 
                </figure>
              </div>
            </div>
          </div>
        </div> */}
        {
          tab===1?
          <div class="container mt-5 px-5">
          <div class="row">
            <div class="col-md-12 text-center">
              <h3 class="production-text text-center"> Info</h3>
            </div>
            <div class="col-md-12">
              <p class="production-text">Production House -<span class="production-text2">{item?.production_house}</span> </p>
            </div>
            <div class="col-md-12">
              <p class="production-text">Estimated budget -<span class="production-text2">{item?.estimated_budget}
              </span>
              </p>
            </div>
            <div class="col-md-12 text-center">
              <h3 class="production-text text-center">
                {" "}
                Expected time of completion
              </h3>
            </div>
            <div class="col-md-12">
              <p class="production-text"> Pre production-<span class="production-text2">{item?.pre_production}</span></p>
            </div>
            <div class="col-md-12">
              <p class="production-text">Production-<span class="production-text2">
                {item?.production}
                </span>
                </p>
            </div>
            <div class="col-md-12">
              <p class="production-text">Post production-
              <span class="production-text2">
              {item?.post_production}
              </span>
              </p>
            </div>
            <div class="col-md-12">
              <p class="production-text">Format-<span class="production-text2">{item?.format}</span></p>
              <p class="production-text">Genre-<span class="production-text2">{item?.genre}</span></p>
            </div>
          </div>
        </div>
      :
      tab==2?
      <div class="container mt-5 px-5">
          <div class="row">
            <div style={{}} class="col-md-12 text-center">
              <h3  class="production-text text-center"> Financials</h3>
            </div>
            <div class="col-md-12">
              <p class="production-text">Total estimated budget -<span class="production-text2">{item?.total_estimated_budget}</span></p>
            </div>
            <div class="col-md-12">
              <p class="production-text">Estimated profit -<span class="production-text2">{item?.estimated_profit}</span></p>
            </div>
            <div class="col-md-12">
              <p class="production-text">Estimated payout date -<span class="production-text2">{item?.estimated_payout_date}</span></p>
            </div>
            <div class="col-md-12 text-center">
              <h3 class="production-text text-center">
                {" "}
                Pre-production 
              </h3>
            </div>
            <div class="col-md-12">
              <p class="production-text">Signing in bonus-<span class="production-text2">{item?.pre_production_signing_bonus}</span></p>
            </div>
            <div class="col-md-12">
              <p class="production-text">Script writing-<span class="production-text2">{item?.pre_production_script_writing}</span></p>
            </div>
            <div class="col-md-12">
              <p class="production-text">Miscellaneous-<span class="production-text2">{item?.pre_production_miscellaneous}</span></p>
            </div>
            <div class="col-md-12 text-center">
              <h3 class="production-text text-center">
                {" "}
                Production
              </h3>
            </div>
            <div class="col-md-12">
              <p class="production-text">Actors-<span class="production-text2">{item?.production_actors}</span></p>
            </div>
            <div class="col-md-12">
              <p class="production-text">Crew member-<span class="production-text2">{item?.production_crew_member}</span></p>
            </div>
            <div class="col-md-12">
              <p class="production-text">Supporting actor-<span class="production-text2">{item?.production_supporting_actor}</span></p>
            </div>
            <div class="col-md-12">
              <p class="production-text">Director-<span class="production-text2">{item?.production_director}</span></p>
            </div>
            <div class="col-md-12">
              <p class="production-text">Equipments-<span class="production-text2">{item?.production_equipments}</span></p>
            </div>
            <div class="col-md-12">
              <p class="production-text">Miscellaneous-<span class="production-text2">{item?.production_miscellaneous}</span></p>
            </div>
            <div class="col-md-12 text-center">
              <h3 class="production-text text-center">
                {" "}
                Post production
              </h3>
            </div>
            <div class="col-md-12">
              <p class="production-text">Editing-<span class="production-text2">{item?.post_production_editing}</span></p>
            </div>
            <div class="col-md-12">
              <p class="production-text">Marketing-<span class="production-text2"> {item?.post_production_marketing}</span></p>
            </div>
            <div class="col-md-12">
              <p class="production-text">Miscellaneous-<span class="production-text2">{item?.post_production_miscellaneous}</span></p>
            </div>
           
           
          </div>
        </div>  :
        tab===4?
        <div class="container mt-5 px-5">
        <div class="row">
          <div class="col-md-12 text-center">
            <h3 class="production-text text-center"> Perks</h3>
          </div>
          <div class="col-md-12">
            <p class="production-text"><span class="production-text2">{item?.parks}</span></p>
          </div>
          
          
        </div>
      </div>
        :
        tab===3?
        <div class="container mt-5 px-5">
        <div class="row">
          <div class="col-md-12 text-center">
            <h3 class="production-text text-center">Graphics</h3>
          </div>
          
        </div>
      </div>
        :
        <div class="container mt-5 px-5">
        <div class="row">
          <div class="col-md-12 text-center">
            <h3 class="production-text text-center">Extras</h3>
          </div>
          
        </div>
      </div>
      }
      </section>

      <AddNewPaymentMethod
        show={paymentPopup}
        toggle={() => setPaymentPopup(!paymentPopup)}
      />
    </div>
  );
}

export default Descriptions;
