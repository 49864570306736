import React, { useEffect, useState } from "react";
import { Modal, Alert, Spinner } from "react-bootstrap";

import { toast } from "react-toastify";

import { useSelector, useDispatch } from "react-redux";

//store
import { createPayment } from "../../Redux/Action/AuthenticationAction";
import AddMovieForm from "../Home/addMovies";

const MoviePopup = (props) => {
  const disptach = useDispatch();
  const { show, toggle } = props;

  return (
    <Modal
      id="adddeliveruadd"
      size="md"
      show={show}
      autoFocus={true}
      centered={true}
      onHide={() => toggle()}
      wrapClassName="myaddreess-modal"
      contentClassName=""
      fade
    >
      <div
        className="containerModal"
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          zIndex: 9999999,
        }}
      >
        <div className="modal-header">
          <div>
            <h4 className="pop-up-title" id="staticBackdropLabel">
              Add Movie
            </h4>
          </div>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => toggle()}
          ></button>
        </div>

        <div className="modal-body spinner-content position-relative">
          <div className="payment-method3">
            {/* <div className="payment-col">
              <label>Enter Amount</label>
              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                style={{ width: "100%" }}
              />


            
            </div> */}
            <AddMovieForm />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MoviePopup;
