// <!DOCTYPE html>
// <html lang="en">
//   <head>
//     <meta charset="UTF-8" />
//     <meta name="viewport" content="width=device-width, initial-scale=1.0" />
//     <title>Create-post</title>
//     <link
//       href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css"
//       rel="stylesheet"
//       integrity="sha384-4bw+/aepP/YC94hEpVNVgiZdgIC5+VKNBQNGCHeKRQN+PtmoHDEXuppvnDJzQIu9"
//       crossorigin="anonymous"
//     />
//     <link rel="stylesheet" href="style.css" />
//   </head>
//   <body>
//     <div className="main">
//       <section>
//         <div className="navvar">
//           <!-- <div className="container">
//             <div className="py-2">
//               <div className="row">
//                 <div className="col-2">
//                   <img
//                     src="./clubboxx-logo.png"
//                     className="img-fluid logo-clubbox"
//                     alt=""
//                   />
//                 </div>
//                 <div className="col-6 d-flex align-items-center">
//                   <form className=" " role="search" style="width: 100%">
//                     <input
//                       className="form-control me-2 border-rounded"
//                       type="search"
//                       placeholder="Search"
//                       aria-label="Search"
//                     />
//                   </form>
//                 </div>
//                 <div className="col-2 d-flex justify-content-center align-items-center"
//                 >
//                   <img src="./brost..png" className="img-fluid" alt="" />
//                 </div>
//                 <div className="col-2 d-flex justify-content-center align-items-center"
//                 >
//                   <img src="./icon 1.png" className="img-fluid" alt="" />
//                 </div>
//               </div>
//             </div>
             
//           </div> -->
//           <nav className="navbar navbar-expand-lg bg-body-tertiary">
//             <div className="container">
//               <a className="navbar-brand" href="#">
//                 <img
//                   src="./clubboxx-logo.png"
//                   className="img-fluid logo-clubbox"
//                   alt=""
//               /></a>
//               <div className="col-6 d-flex align-items-center">
//                 <form className=" " role="search" style="width: 100%">
//                   <input
//                     className="form-control me-2 border-rounded"
//                     type="search"
//                     placeholder="Search"
//                     aria-label="Search"
//                   />
//                 </form>
//               </div>
//               <button
//                 className="navbar-toggler"
//                 type="button"
//                 data-bs-toggle="collapse"
//                 data-bs-target="#navbarSupportedContent"
//                 aria-controls="navbarSupportedContent"
//                 aria-expanded="false"
//                 aria-label="Toggle navigation"
//               >
//                 <span className="navbar-toggler-icon"></span>
//               </button>

//               <div className="collapse navbar-collapse" id="navbarSupportedContent">
//                 <ul className="navbar-nav me-auto mb-2 mb-lg-0">
//                   <li className="nav-item">
//                     <a className="nav-link active" aria-current="page" href="#"
//                       >Home</a
//                     >
//                   </li>
//                   <li className="nav-item">
//                     <a className="nav-link" href="#">Link</a>
//                   </li>
//                   <li className="nav-item dropdown">
//                     <a
//                       className="nav-link dropdown-toggle"
//                       href="#"
//                       role="button"
//                       data-bs-toggle="dropdown"
//                       aria-expanded="false"
//                     >
//                       Dropdown
//                     </a>
//                     <ul className="dropdown-menu">
//                       <li><a className="dropdown-item" href="#">Action</a></li>
//                       <li>
//                         <a className="dropdown-item" href="#">Another action</a>
//                       </li>
//                       <li><hr className="dropdown-divider" /></li>
//                       <li>
//                         <a className="dropdown-item" href="#"
//                           >Something else here</a
//                         >
//                       </li>
//                     </ul>
//                   </li>
//                   <li className="nav-item">
//                     <a className="nav-link disabled" aria-disabled="true"
//                       >Disabled</a
//                     >
//                   </li>
//                 </ul>
//                 <form className="d-flex" role="search">
//                   <input
//                     className="form-control me-2"
//                     type="search"
//                     placeholder="Search"
//                     aria-label="Search"
//                   />
//                   <button className="btn btn-outline-success" type="submit">
//                     Search
//                   </button>
//                 </form>
//               </div>
//             </div>
//           </nav>
//         </div>
//       </section>
//       <section className="mt-3">
//         <div className="container">
//           <div className="row">
//             <div className="offset-4 col-4">
//               <div className="feed-btn-active">
//                 <button className="feed-btn">Post</button>
//               </div>
//             </div>
             
             
//           </div>
//         </div>
//       </section>
//       <section className="my-3">
//         <div className="container">
//             <div className="form-floating">
//                 <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px"></textarea>
//                 <label for="floatingTextarea2">What you want to talk about ?</label>
//               </div>
//         </div>
       
//       </section>

//       <section className="my-3">
//         <div className="container">
//             <div className="form-floating">
//                 <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 300px"></textarea>
//                 <label for="floatingTextarea2">Question of discussion</label>
//               </div>
//         </div>
       
//       </section>

     
       
      
//       <section className="add-btn create-post my-3">
//         <div className="container text-end">
//             <div className="offset-4 col-4">
//                 <div className="feed-btn-active-1">
//                   <button className="feed-btn fw-bold">Add Posts</button>
//                 </div>
//               </div>
//         </div>
//       </section>

//       <section className="mt-3">
//         <div className="container">
//           <div className="row bottom-menu">
//             <div className="col">
//               <button className="feed-btn-bottom">Home</button>
//             </div>
//             <div className="col">
//               <button className="feed-btn-bottom">ENGAGEMENTS</button>
//             </div>
//             <div className="col">
//               <button className="feed-btn-bottom">PROFILE</button>
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>

//     <script
//       src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.8/dist/umd/popper.min.js"
//       integrity="sha384-I7E8VVD/ismYTF4hNIPjVp/Zjvgyol6VFvRkX/vR+Vc4jQkC+hVqc2pM8ODewa9r"
//       crossorigin="anonymous"
//     ></script>
//     <script
//       src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/js/bootstrap.min.js"
//       integrity="sha384-Rx+T1VzGupg4BHQYs2gCW9It+akI2MM/mndMCy36UVfodzcJcF0GGLxZIzObiEfa"
//       crossorigin="anonymous"
//     ></script>
//   </body>
// </html>
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { toast } from 'react-toastify';
import {storage} from '../../Components/firebaseConfig';
import { createPost } from '../../Redux/Action/CreateJobAction';
import AppImages from '../../Themes/AppImages'
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

const clubboxx =require("./clubboxx-logo.png").default
export default function Createpost() {
  const history = useHistory();
  const [title,setTitle]=useState('')
  const dispatch=useDispatch()
  const [content,setContent]=useState('')
  const [selectedFile,setSelectedFile]=useState('')
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // setSelectedFile(file);

    const storageRef = ref(storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on("state_changed",
      (snapshot) => {
        console.log('snapshot',snapshot);
        const progress =
          Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        // setProgresspercent(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log('downloadURL',downloadURL);
          setSelectedFile(downloadURL)
          
        });
      }
    )
  };
  useEffect(() => {
    if (!localStorage.token) {
        history.push("/Login")
    }
}, [])
   const onPressSubmit=()=>{
    var form = document.querySelector('form');

    const formDatas = {
      imageUrl:selectedFile,
      title:title,
      content:content,
      token:localStorage.token
    }

    setTimeout(() => {
      
      console.log('formDatas',formDatas);
    }, 2000);
    dispatch(createPost(formDatas))
   }
  return (
    <div className="main">
    <section>
      <div className="navvar">
        <div className="container">
          <div className="py-2">
          <div className="row">
              <div className="col-2">
                <img
                  src={AppImages.clubboxxlogo1}
                  className="img-fluid logo-clubbox"
                  alt=""
                />
              </div>
              <div className="col-6 d-flex align-items-center">
                <form className=" " role="search" style={{width:'100%'}}>
                  <input
                    className="form-control me-2 border-rounded"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                </form>
              </div>
              <div className="col-2 d-flex justify-content-center align-items-center"
              >
                <img src={AppImages.brost} className="img-fluid" alt="" />
              </div>
              <div className="col-2 d-flex justify-content-center align-items-center"
              >
                <img src={AppImages.userIcon} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
           
        </div> 
        {/* <nav className="navbar navbar-expand-lg bg-body-tertiary">
          <div className="container">
            <a className="navbar-brand" href="#">
              <img
                src="./clubboxx-logo.png"
                className="img-fluid logo-clubbox"
                alt=""
            /></a>
            <div className="col-6 d-flex align-items-center">
              <form className=" " role="search" style={{width:'100%'}}>
                <input
                  className="form-control me-2 border-rounded"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                />
              </form>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="#"
                    >Home</a
                  >
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">Link</a>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Dropdown
                  </a>
                  <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="#">Action</a></li>
                    <li>
                      <a className="dropdown-item" href="#">Another action</a>
                    </li>
                    <li><hr className="dropdown-divider" /></li>
                    <li>
                      <a className="dropdown-item" href="#"
                        >Something else here</a
                      >
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a className="nav-link disabled" aria-disabled="true"
                    >Disabled</a
                  >
                </li>
              </ul>
              <form className="d-flex" role="search">
                <input
                  className="form-control me-2"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                />
                <button className="btn btn-outline-success" type="submit">
                  Search
                </button>
              </form>
            </div>
          </div>
        </nav> */}
      </div>
    </section>
    <section className="mt-3">
      <div className="container">
        <div className="row">
          <div className="offset-4 col-4">
            <div className="feed-btn-active">
              <button
              onClick={()=>{
                onPressSubmit()
              }}
              className="feed-btn">Post</button>
            </div>
          </div>
           
           
        </div>
      </div>
    </section>
    <section className="my-3">
      <div className="container">
          <div className="form-floating">
              <textarea 
              onChange={(x)=>{
                setTitle(x.target.value)
              }}
              className="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style={{height:100}}></textarea>
              <label for="floatingTextarea2">What you want to talk about ?</label>
            </div>
      </div>
     
    </section>

    <section className="my-3">
      <div className="container">
          <div className="form-floating">
              <textarea
               onChange={(x)=>{
                setContent(x.target.value)
              }}
               className="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style={{height: 300}}></textarea>
              <label for="floatingTextarea2">Question of discussion</label>
            </div>
      </div>
     
    </section>

    <section className="add-btn create-post my-3">
      <div className="container text-end">
          <div className="offset-4 col-4">
              {/* <div className="feed-btn-active-1"> */}
              {selectedFile && (
        <div >
          {/* <p>Selected file: {selectedFile}</p> */}
          <img style={{height:100,width:100,borderRadius:20}} src={selectedFile} alt="Selected" />
        </div>
      )}
                {/* <button className="feed-btn fw-bold">Add Posts</button> */}
              <input type="file" accept="image/*" onChange={handleFileChange} />
              {/* </div> */}
            </div>
      </div>
    </section>

    <section className="mt-3">
      <div className="container">
        <div className="row bottom-menu">
          <div className="col">
            <button
            style={{color:'#3248BC'}}
            onClick={()=>{history.goBack()}}
            className="feed-btn-bottom">HOME</button>
          </div>
          <div className="col">
            <button className="feed-btn-bottom">ENGAGEMENTS</button>
          </div>
          <div className="col">
            <button
            onClick={()=>{history.push('./Profile')}}
            className="feed-btn-bottom">PROFILE</button>
          </div>
        </div>
      </div>
    </section>
  </div>
  )
}