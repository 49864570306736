import React, { useState, useId  } from "react";
import { useDispatch } from "react-redux";
import Axios from "axios" 
import { v4 as uuidv4 } from 'uuid';
import { NavLink, useHistory } from 'react-router-dom';
import { createMovie } from "../../Redux/Action/AuthenticationAction";

// import "./AddMovieForm.css";

let otherFields={
   infoDescription: "",
    crew_member: "",
    production_house: "",
    estimated_budget: "",
    expected_time_completion: "",
    pre_production: "",
    production: "",
    post_production: "",
    plot: "",
    investable_rating: "",
    development_stage: "",
    age: "",
    format: "",
    genre: "",
    total_estimated_budget: "",
    estimated_profit: "",
    estimated_payout_date: "",
    pre_production_signing_bonus: "",
    pre_production_script_writing: "",
    pre_production_miscellaneous: "",
    vote_count: "",
    production_supporting_actor: "",
    production_director: "",
    production_equipments: "",
    production_actors: "",
    production_crew_member: "",
    production_miscellaneous: "",
    post_production_editing: "",
    post_production_marketing: "",
    post_production_miscellaneous: "",
    parks: "",
    extras: "",
    preferable_shoot: "",
    director_vision: "",
    character_looks: "",
}



const AddMovieForm = ({ onAddMovie }) => {
   const history = useHistory();
 const dispatch = useDispatch()
  const [fields, setFields]= useState({
    id: uuidv4(),
    title: "",
    description: "",
    vote_average: "",
    vote_count: "",
    release_date: "",
    amount: "",
    imgUrl: "",
    backdrop_path:"",
    adult: "",
    original_language: "",
    popularity: "",
    video: false,

    ...otherFields
  });


  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(createMovie(fields,(success)=>{
      if(success){
        history.push("/InvestNow#")
      }
    } ))

  };


  const handleFileChange =async (event, name) => {
    
      try {
      const file = event.target.files[0];
      const formData = new FormData()
      formData.append("file", file)
      const config = {
        headers: { "content-type": "multipart/form-data" },
      }

      let response = await Axios.post("https://api.brost.host/upload", formData, { ...config })
handleChange({
  target:{
    name:name,
    value: response.data.data
  }
})
    } catch (error) {

    console.log(error, "<===err")
    }

    
  };

  const handleChange=(e)=>{

    const {name, value}= e.target;
    setFields(pre=>({
      ...pre,
      [name]: value
    }))
  }

console.log(fields.id, "<===id")

  return (
    <form onSubmit={handleSubmit} className="add-movie-form">
      <label> <b>Main Image</b></label>
      <input
        type="file"
        accept="image/*"
        onChange={(e)=> handleFileChange(e, "imgUrl")}
        required
      />
      <label> <b>Cover Image</b></label>

           <input
        type="file"
        accept="image/*"
        onChange={(e)=> handleFileChange(e, "backdrop_path")}
        required
      />
      <input
      type={'text'}
        placeholder="Name"
        value={fields.title}
        name="title"
        onChange={handleChange}
        required
      />
      <textarea
        placeholder="Description"
      name="description"
        value={fields.description}
        onChange={handleChange}
        required
      />
      <input
        type="number"
        placeholder="Vote Count"
        value={fields.vote_count}
        name="vote_count"
        onChange={handleChange}
        required
      />
          <input
        type="date"
        placeholder="Relase date"
        value={fields.release_date}
        name="release_date"
        onChange={handleChange}
        required
      />
         <input
        type="number"
        placeholder="Vote Average"
        value={fields.vote_average}
        name="vote_average"
        onChange={handleChange}
        required
      />
      <input
        type="number"
        placeholder=" Amount"
        name="amount"
        value={fields.amount}
        onChange={handleChange}
        required
      />

           <select
        type="select"
        placeholder=" Adult"
        name="adult"
        value={fields.adult}
        onChange={handleChange}
        required

      >


        <option value="">Select is Adult?</option>
        <option value={true}>Yes</option>
        <option value={false}>No</option>
      </select>

         <input
        type="text"
        placeholder=" Language"
        name="original_language"
        value={fields.original_language}
        onChange={handleChange}
        required
      />
      <input
        type="number"
        placeholder="popularity"
              name="popularity"
        value={fields.popularity}
        onChange={handleChange}
        required
      />



      {
        Object.entries(otherFields).map(([key, values])=>(
           <input
        type="text"
        placeholder={key.replace(/_/g, ' ') || key}
        name={key}
        value={fields[{key}]}
        onChange={handleChange}
        required
      />
        ))
      }
      <button type="submit">Add Movie</button>
    </form>
  );
};

export default AddMovieForm;
