const AppImages = {
  monoPlug: require("../Assets/Images/mono-logo.png").default,
  user_img: require("../Assets/Images/user_img.png").default,
  menu: require("../Assets/Images/newmenu.png").default,
  mail: require("../Assets/Images/mail.svg").default,
  password: require("../Assets/Images/password.svg").default,
  circle: require("../Assets/Images/circle.png").default,
  profile: require("../Assets/Images/profile2.svg").default,
  user: require("../Assets/Images/user.svg").default,
  arrow1: require("../Assets/Images/arrow.png").default,
  img: require("../Assets/Images/Bell-Bottom-movie-re1.png").default,
  img1: require("../Assets/Images/Bell-Bottom-movie-re.png").default,
  img2: require("../Assets/Images/Bell-Bottom-movie-re1.png").default,
  horn: require("../Assets/Images/horn.png").default,
  bag: require("../Assets/Images/bag.png").default,
  close: require("../Assets/Images/close (1).svg").default,
  arrow2: require("../Assets/Images/arrow (2).png").default,
  newDetails: require("../Assets/Images/img.png").default,
  stories: require("../Assets/Images/music1.png").default,
  rightArrow: require("../Assets/Images/arrow2.png").default,
  star: require("../Assets/Images/star.png").default,
  title: require("../Assets/Images/title.svg").default,
  description: require("../Assets/Images/description.svg").default,
  category: require("../Assets/Images/Category.svg").default,
  subCategory: require("../Assets/Images/sub category.svg").default,
  budget: require("../Assets/Images/budget.svg").default,
  address: require("../Assets/Images/address.svg").default,
  man: require("../Assets/Images/man.png").default,
  invitation: require("../Assets/Images/invitation.svg").default,
  smile: require("../Assets/Images/smile.png").default,
  closeLine: require("../Assets/Images/close_line.png").default,
  imgLine: require("../Assets/Images/image_line.png").default,
  edit: require("../Assets/Images/edit_img.png").default,
  thanku: require("../Assets/Images/thank-you.png").default,
  apple: require("../Assets/Images/apple.png").default,
  googleplay: require("../Assets/Images/googleplay.png").default,
  facebook: require("../Assets/Images/facebook.png").default,
  instagram: require("../Assets/Images/instagram.png").default,
  mokeup: require("../Assets/Images/mokup.png").default,
  rArrow: require("../Assets/Images/right_arrow.png").default,
  lArrow: require("../Assets/Images/left_arrow.png").default,
  scroll: require("../Assets/Images/scroll.png").default,
  splash: require("../Assets/Images/splash.png").default,
  dislike: require("../Assets/Images/dislike.png").default,
  star12: require("../Assets/Images/star12.png").default,
  tick: require("../Assets/Images/tick.png").default,
  star1: require("../Assets/Images/star1.png").default,
  music1: require("../Assets/Images/music1.png").default,
  plus: require("../Assets/Images/add2.svg").default,
  wave: require("../Assets/Images/wave.png").default,
  user_Vector: require("../Assets/Images/user-vector.png").default,
  clubboxxlogo1: require("../Assets/clobbox/clubboxxlogo1.jpeg").default,
  // brost: require("../Assets/clobbox/brost.png").default,
  userIcon: require("../Assets/clobbox/icon1.png").default,
  // BrostGif: require("../Assets/clobbox/brost gif.gif").default,
  Clubboxx_Gif_Final: require("../Assets/clobbox/Clubboxx_Gif_Final.gif").default,
  g12: require("../Assets/clobbox/g12.png").default,
  asd: require("../Assets/clobbox/asd.png").default,
  fourm: require("../Assets/clobbox/fourm.png").default,
  news: require("../Assets/clobbox/news.png").default,
  homeClubbox: require("../Assets/clobbox/homeClubbox.jpeg").default,
  poster1:require('../Assets/Images/poster1.jpeg').default,
  poster2:require('../Assets/Images/poster2.jpeg').default,
  poster3:require('../Assets/Images/poster3.jpeg').default,
  poster4:require('../Assets/Images/poster4.jpeg').default,
  poster5:require('../Assets/Images/poster5.jpeg').default,

};

export default AppImages;
